<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.groupprice/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="id" title="活动ID" align="center" min-width="20px" />
			<vxe-table-column slot="table-item" field="name" title="名称" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="platform" title="生效平台" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{$platform[row.platform]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="btime" title="开始时间" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="etime" title="结束时间" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="status" title="状态" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{row.status==1?'有效':'无效'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="lastname" title="最后操作人" align="center" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="300px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="dataOne(row)">数据</el-button>
					<el-button size="small" plain @click="detail(row)">详情</el-button>
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.groupprice/edit')">编辑</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 编辑 -->
		<el-dialog title="编辑/新增" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="1200px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="名称" prop="name">
					<el-input v-model="editDialogFormData.name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="editDialogFormData.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime"
						type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime"
						type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="优惠设置" prop="cfg">
					<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addCfg">增加时段</el-button>
					<div class="priceBox">
						<!--<div v-for="(item,idx) in editDialogFormData.prices" v-bind:key="idx">
							<table>
								<tr>
									<th><el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="onItemDel(idx)"></el-button></th>
									<th>电费</th>
									<th>服务费</th>
								</tr>
								<tr>
									<td>时间</td>
									<td><el-time-picker v-model="editDialogFormData.prices[idx][0]" picker-options="00:00-23:59" style="width:100px" format="HH:mm" value-format="HH:mm" placeholder="开始" size="mini"></el-time-picker></td>
									<td><el-time-picker v-model="editDialogFormData.prices[idx][1]" style="width:100px" format="HH:mm" value-format="HH:mm" placeholder="结束" size="mini"></el-time-picker></td>
								</tr>
								<tr>
									<td>非会员</td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.prices[idx][2]" style="width: 120px;" class="input" size="small"></el-input-number></td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.prices[idx][3]" style="width: 120px;" class="input" size="small"></el-input-number></td>
								</tr>
								<tr>
									<td>会员V1</td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.prices[idx][4]" style="width: 120px;" class="input" size="small"></el-input-number></td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.prices[idx][5]" style="width: 120px;" class="input" size="small"></el-input-number></td>
								</tr>
								<tr>
									<td>会员V2</td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.prices[idx][6]" style="width: 120px;" class="input" size="small"></el-input-number></td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.prices[idx][7]" style="width: 120px;" class="input" size="small"></el-input-number></td>
								</tr>
								<tr>
									<td>会员V3</td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.prices[idx][8]" style="width: 120px;" class="input" size="small"></el-input-number></td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.prices[idx][9]" style="width: 120px;" class="input" size="small"></el-input-number></td>
								</tr>
								<tr>
									<td>会员V4</td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.prices[idx][10]" style="width: 120px;" class="input" size="small"></el-input-number></td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.prices[idx][11]" style="width: 120px;" class="input" size="small"></el-input-number></td>
								</tr>
							</table>
						</div>-->
						<div v-for="(item,idx) in editDialogFormData.nPrices" v-bind:key="idx">
							<table>
								<tr>
									<th><el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="onItemDel(idx)"></el-button></th>
									<th>电费</th>
									<th>服务费</th>
								</tr>
								<tr>
									<td>时间</td>
									<td><el-time-picker v-model="editDialogFormData.nPrices[idx][0]" picker-options="00:00-23:59" style="width:100px" format="HH:mm" value-format="HH:mm" placeholder="开始" size="mini"></el-time-picker></td>
									<td><el-time-picker v-model="editDialogFormData.nPrices[idx][1]" style="width:100px" format="HH:mm" value-format="HH:mm" placeholder="结束" size="mini"></el-time-picker></td>
								</tr>
								<tr>
									<td>非会员</td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.nPrices[idx][2]" style="width: 120px;" class="input" size="small"></el-input-number></td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.nPrices[idx][3]" style="width: 120px;" class="input" size="small"></el-input-number></td>
								</tr>
								<tr>
									<td>常规VIP</td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.nPrices[idx][4]" style="width: 120px;" class="input" size="small"></el-input-number></td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.nPrices[idx][5]" style="width: 120px;" class="input" size="small"></el-input-number></td>
								</tr>
								<tr>
									<td>线下年卡VIP</td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.nPrices[idx][6]" style="width: 120px;" class="input" size="small"></el-input-number></td>
									<td><el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4" v-model="editDialogFormData.nPrices[idx][7]" style="width: 120px;" class="input" size="small"></el-input-number></td>
								</tr>
							</table>
						</div>
					</div>
				</el-form-item>	
				<el-form-item label="参与电站" prop="group_ids">
					<el-select v-model="editDialogFormData.group_ids" placeholder="请选择场站" multiple style="width:100%">
						<el-option v-for="(item,k) in groupList" :key="k" :label="item.group_name" :value="item.group_id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否有效" prop="status">
					<el-switch v-model="editDialogFormData.status" active-value="1" inactive-value="0" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
		<!-- 详情 -->
		<el-dialog title="详情" :visible.sync="detailShow" :close-on-click-modal="false" :close-on-press-escape="false" width="600px">
			<hr/>
			<el-descriptions title="活动信息" :column="1">
				<el-descriptions-item label="活动名称">{{editDialogFormData.name}}</el-descriptions-item>
				<el-descriptions-item label="活动时间">{{editDialogFormData.btime}}~{{editDialogFormData.etime}}</el-descriptions-item>
			</el-descriptions>
			<hr/>
			<el-descriptions title="优惠信息" :column="1" :colon="false">
				<template v-if="editDialogFormData.nPrices != null" v-for="(item,idx) in editDialogFormData.nPrices">
					<el-descriptions-item label="时间段">{{item[0]}}-{{item[1]}}</el-descriptions-item>
					<el-descriptions-item label="优惠价格"></el-descriptions-item>
					<el-descriptions-item label=""><div class="priceShowBox"><a>非会员</a><a>常规VIP</a><a>线下年卡VIP</a></div></el-descriptions-item>	
					<el-descriptions-item label="电费"><div class="priceShowBox"><a>{{ item[2] }}</a><a>{{ item[4] }}</a><a>{{ item[6] }}</a></div></el-descriptions-item>		
					<el-descriptions-item label="服务费"><div class="priceShowBox"><a>{{ item[3] }}</a><a>{{ item[5] }}</a><a>{{ item[7] }}</a></div></el-descriptions-item>			
				</template>
				<template v-if="editDialogFormData.nPrices == null" v-for="(item,idx) in editDialogFormData.prices">
					<el-descriptions-item label="时间段">{{item[0]}}-{{item[1]}}</el-descriptions-item>
					<el-descriptions-item label="优惠价格"></el-descriptions-item>
					<el-descriptions-item label=""><div class="priceShowBox"><a>非会员</a><a>会员V1</a><a>会员V2</a><a>会员V3</a><a>会员V4</a></div></el-descriptions-item>	
					<el-descriptions-item label="电费"><div class="priceShowBox"><a>{{ item[2] }}</a><a>{{ item[4] }}</a><a>{{ item[6] }}</a><a>{{ item[8] }}</a><a>{{ item[10] }}</a></div></el-descriptions-item>		
					<el-descriptions-item label="服务费"><div class="priceShowBox"><a>{{ item[3] }}</a><a>{{ item[5] }}</a><a>{{ item[7] }}</a><a>{{ item[9] }}</a><a>{{ item[11] }}</a></div></el-descriptions-item>			
				</template>
			</el-descriptions>
			<hr/>
			<el-descriptions title="参与场站" :column="1" :colon="false">
				<template v-for="(item,idx) in editDialogFormData.group_ids">
					<el-descriptions-item label="">{{groupMap[item]}}</el-descriptions-item>		
				</template>
			</el-descriptions>
		</el-dialog>
		<!-- 数据 -->
		<el-dialog title="数据详情" :visible.sync="dataShow" :close-on-click-modal="false" :close-on-press-escape="false" width="600px">
			<hr/>
			<el-descriptions title="活动信息" :column="1">
				<el-descriptions-item label="活动名称">{{editDialogFormData.name}}</el-descriptions-item>
				<el-descriptions-item label="活动时间">{{editDialogFormData.btime}}~{{editDialogFormData.etime}}</el-descriptions-item>
			</el-descriptions>
			<hr/>
			<el-descriptions title="订单分析" :column="3" direction="vertical" :colon="false">
				<el-descriptions-item label="累计充电金额">{{ groupData.pay_money }}</el-descriptions-item>
				<el-descriptions-item label="累计电费">{{ groupData.pay_ele }}</el-descriptions-item>
				<el-descriptions-item label="累计服务费">{{ groupData.pay_service }}</el-descriptions-item>
				<el-descriptions-item label="累计优惠金额">{{ groupData.discount }}</el-descriptions-item>
				<el-descriptions-item label="累计充电量">{{ groupData.electric }}</el-descriptions-item>
				<el-descriptions-item label="累计订单数">{{ groupData.order_num }}</el-descriptions-item>
			</el-descriptions>
			<el-descriptions title="用户分析" :column="3" direction="vertical" :colon="false">
				<el-descriptions-item label="累计充电用户">{{ groupData.fans_num }}</el-descriptions-item>
				<el-descriptions-item label="带来新用户">{{ groupData.fans_new }}</el-descriptions-item>
				<el-descriptions-item label="带回流失用户">{{ groupData.fans_lost }}</el-descriptions-item>
			</el-descriptions>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'

	export default {
		name: 'market-group-price',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				// 表格信息
				tableName: '电站优惠',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [],
				groupList:[], //场站列表
				groupMap:{}, 
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					platform: [{
						required: true,
						message: '请选择生效平台',
						trigger: 'change'
					}],
					btime: [{
						required: true,
						message: '请输入活动开始时间',
						trigger: 'change'
					}],
					etime: [{
						required: true,
						message: '请输入活动结束时间',
						trigger: 'change'
					}]
				},
				// 编辑
				editDialogShow: false,
				//editDialogFormData: {group_ids:[],prices:[]},
				editDialogFormData: {group_ids:[],nPrices:[]},
				detailShow: false, //详情对话框是否显示
				dataShow: false, //数据对话框是否显示
				groupData: {},
			}
		},
		computed: {
		},
		mounted() {
			//this.getList();
			const params = {
				token: this.$store.state.user.token,
				page: 1,
				size: 100,
				city: this.editDialogFormData.citys
			}
			this.$api.Device.GetGroupMinList(params).then((res)=>{
				this.groupList = res;
				for(let v of this.groupList){
					this.groupMap[v.group_id] = v.group_name;
				}
				let gids = [];
				for(let gid of this.editDialogFormData.group_ids){
					for(let gv of this.groupList){
						if(gv.group_id == gid){
							gids.push(gid);
						}
					}
				}
				this.editDialogFormData.group_ids = gids;
			});
		},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					tp: 3,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.GroupPrice.list(params);
				for(let v of res.data){
					v.group_ids = JSON.parse(v.group_ids);
					v.prices = JSON.parse(v.prices);
					v.nPrices = JSON.parse(v.nPrices);
					v.btime = v.btime.substr(0, 10);
					v.etime = v.etime.substr(0, 10);
					if(v.log){
						v.log = JSON.parse(v.log);
						v.lastname = v.log[v.log.length-1].N;
					}
				} 
				this.List = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				//this.editDialogFormData = {group_ids:[],prices:[]}
				this.editDialogFormData = {group_ids:[],nPrices:[]}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.editDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData();
				row.status = row.status.toString();
				this.editDialogFormData = Object.assign({}, row);
				if(!this.editDialogFormData.nPrices){
					this.editDialogFormData.nPrices = [];
				}
				delete this.editDialogFormData._XID;
				delete this.editDialogFormData.inviteTxt;
				delete this.editDialogFormData.getInviteTxt;
				delete this.editDialogFormData.backTxt;
				delete this.editDialogFormData.lastname;
				this.editDialogShow = true
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Market.GroupPrice.del(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						let params = {
							token: this.$store.state.user.token,
							form: Object.assign({}, this.editDialogFormData)
						}
						params.form.group_ids = JSON.stringify(params.form.group_ids);
						// for(let j=0;j<this.editDialogFormData.prices.length;j++){
						// 	let pv = this.editDialogFormData.prices[j];
						// 	for(let i=4;i<pv.length;i+=2){
						// 		if(pv[i] != pv[2]){
						// 			return this.$notify({title:'失败', message: 'VIP电价不能调整', type:'error'});
						// 		}
						// 	}
						// }
						params.form.prices = JSON.stringify(params.form.prices);
						params.form.nPrices = JSON.stringify(params.form.nPrices);
						params.form = JSON.stringify(params.form);
						if(this.editDialogFormData.id > 0){
							await this.$api.Market.GroupPrice.edit(params);
						}else{
							await this.$api.Market.GroupPrice.add(params);
						}
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			detail(row) { // 站点优惠详细
				this.editDialogFormData = row;
				this.detailShow = true;
			},				
			async dataOne(row) { //站点优惠数据
				this.editDialogFormData = row;
				this.dataShow = true;
				let params = {
					token: this.$store.state.user.token,
					group_ids: row.group_ids,
					btime: row.btime,
					etime: row.etime,
				}
				this.isLoading = true
				const res = await this.$api.Market.GroupPrice.data(params);
				this.groupData = res;
				this.groupData.pay_money = Number(this.groupData.pay_ele) + Number(this.groupData.pay_service);
				this.groupData.discount = (Number(this.groupData.ele) + Number(this.groupData.service) - this.groupData.pay_money).toFixed(2);
				this.isLoading = false;
			},		
			addCfg(){ //添加挡位配置
				//this.editDialogFormData.prices.push(['','',0,0,0,0,0,0,0,0,0,0]);
				this.editDialogFormData.nPrices.push(['','',0,0,0,0,0,0]);
			},
			onItemDel(idx){ //删除挡位配置
				//this.editDialogFormData.prices.splice(idx, 1);
				this.editDialogFormData.nPrices.splice(idx, 1);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.priceBox{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		div{
			//display: grid;
			//grid-template-columns: 33% 33% 33%;
		}
	}
	.priceShowBox{
		width: 400px;
		display: flex;
		justify-content: space-around;
	}
</style>
